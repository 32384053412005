import logger from "../../logger";
import { getDateNow } from "./getDateNow";

export const registerHelpdeskEvent = ({
  onRequestStart,
  getUser,
  getUserId,
  onUserIdChange,
}) => {
  logger.log("Registering helpdesk listeners");

  const uploadSession = (helpdesk) => () => {
    let userPatch = getUser();
    let userId = getUserId();

    if (!userPatch?.email) {
      let email;
      if (helpdesk === "crisp") {
        email = window.$crisp.get("user:email");
      }

      if (helpdesk === "helpscout") {
        email = window.Beacon("info").visitor?.email;
      }

      if (email) {
        userPatch = { email };
        onUserIdChange(userId, userPatch);
      }
    }

    onRequestStart({
      mode: "session",
      metadata: {
        triggerType: "helpdesk-listener",
        triggerSource: "helpdesk-generic",
        helpdesk,
        helpdeskStartTime: [getDateNow()],
      },
      user: userPatch,
    });
  };

  const uploadSessionCrisp = uploadSession("crisp");

  // Crisp
  try {
    window.$crisp?.push?.(["on", "chat:opened", uploadSessionCrisp]);
  } catch (e) {
    // ignore
  }

  // Front
  const frontInterval = setInterval(() => {
    try {
      const newIsOpen =
        document.querySelector("#front-chat-iframe")?.offsetHeight > 100;

      if (newIsOpen) {
        uploadSession("front")();
      }
    } finally {
      clearInterval(frontInterval);
    }
  }, 10 * 1000);

  // HelpScout
  try {
    window.Beacon?.("on", "open", uploadSession("helpscout"));
  } catch (e) {
    // ignore
  }

  // Intercom
  try {
    window.Intercom?.("onShow", uploadSession("intercom"));
  } catch (e) {
    // ignore
  }

  const uploadSessionLiveChat = uploadSession("livechat");

  // LiveChat
  try {
    window.LiveChatWidget?.on?.("visibility_changed", uploadSessionLiveChat);
  } catch (e) {
    // ignore
  }

  // Tawk
  try {
    if (window.Tawk_API) {
      const oldTawk = window.Tawk_API?.onChatMaximized;
      window.Tawk_API.onChatMaximized = () => {
        oldTawk?.();
        uploadSession("tawk")();
      };
    }
  } catch (e) {
    // ignore
  }

  return {
    remove() {
      try {
        window.$crisp?.push(["off", "chat:opened", uploadSessionCrisp]);
        clearInterval(frontInterval);
        window.Beacon?.("off", "open");
        // Intercom does not support remove listener
        window.LiveChatWidget?.off?.(
          "visibility_changed",
          uploadSessionLiveChat
        );
        // Tawk does not support remove listener
      } catch (e) {
        // ignore
      }
    },
  };
};
