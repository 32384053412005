/* eslint-disable no-console */

const originalConsole = {
  ...console,
};

const loggingEnabled = window.location.hash.includes("bugpilot-debug");

const BANNER = (level) => [
  `%cBugpilot%c [${new Date().toISOString()}] %c[${level}]`,
  "color:white; font-weight: bold; border-radius: 5px; padding:2px 5px; background:black;",
  "color:#999;",
  "color:#6060ff;",
];

const logger = {
  log: (...args) => {
    if (loggingEnabled) {
      originalConsole.log(...BANNER("LOG"), ...args);
    }
  },
  warn: (...args) => {
    // warning and errors are always logged
    originalConsole.warn(...BANNER("WARN"), ...args);
  },
  error: (...args) => {
    // warning and errors are always logged
    originalConsole.error(...BANNER("ERR"), ...args);
  },
  info: (...args) => {
    if (loggingEnabled) {
      originalConsole.info(...BANNER("INFO"), ...args);
    }
  },
  debug: (...args) => {
    if (loggingEnabled) {
      originalConsole.debug(...BANNER("DEBUG"), ...args);
    }
  },
  // Utils
  time: (timerId) => {
    if (loggingEnabled) {
      originalConsole.time("Bugpilot " + timerId);
    }
  },
  timeEnd: (timerId) => {
    if (loggingEnabled) {
      originalConsole.timeEnd("Bugpilot " + timerId);
    }
  },
  timeLog: (timerId) => {
    if (loggingEnabled) {
      originalConsole.timeLog("Bugpilot " + timerId);
    }
  },
};

export default logger;
