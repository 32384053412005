import logger from "../../logger";

export function onWebsocketMessage(websocket, onRequestStart) {
  return (event) => {
    logger.info("registerWebsocketListener()", "websocket message", event.data);
    try {
      const data = JSON.parse(event.data);
      const sessionToken = data.sessionToken || "";
      const userProvidedDescription = data.userProvidedDescription || "";

      websocket.send("acknowledgement");

      if (
        data.action === "grab-a-screenshot" ||
        data.action === "upload-screenshot"
      ) {
        onRequestStart({
          mode: "screenshot",
          sessionToken,
          metadata: {
            triggerType: "wss",
            userProvidedDescription,
          },
        });
        return;
      }

      if (
        data.action === "record-user-screen" ||
        data.action === "show-recording-ui"
      ) {
        onRequestStart({
          mode: "recording",
          sessionToken,
          metadata: {
            triggerType: "wss",
            userProvidedDescription,
          },
        });
      }

      if (data.action === "upload-session") {
        onRequestStart({
          mode: "session",
          sessionToken,
          metadata: {
            triggerType: "wss",
            userProvidedDescription,
          },
        });
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };
}
