import logger from "../../logger";
import { onWebsocketMessage } from "./onWebsocketMessage";
import retry from "./retry";

const USER_ID_CHECK_INTERVAL = 2 * 1000;
const USER_ID_CHECK_ATTEMPTS = Infinity;

const WSS_RECONNECT_TIMEOUT = 5 * 1000;
const WSS_MAX_RECONNECT_ATTEMPTS = Infinity;

let wssClients = [];

const connectWebSocket = ({
  sessionId,
  attemptCount,
  onClose,
  onRequestStart,
}) => {
  const connectionUUID = crypto.getRandomValues(new Uint32Array(1))[0];
  const LOGGER_PREFIX = `WSS ${connectionUUID} #${attemptCount}:`;

  let websocket;

  // close other WebSockets
  wssClients.forEach((client) => {
    if (client) {
      logger.debug("WSS: closing other clients");
      try {
        client.close();
      } catch (_) {
        // ignore
      }
    }
  });

  logger.log(LOGGER_PREFIX, "Starting WS", {
    attemptCount,
    connectionUUID,
  });

  logger.debug(LOGGER_PREFIX, "WS session ID", sessionId);

  const wssUrl = new URL("wss://wss.bugpilot.io");
  wssUrl.searchParams.set("sessionId", sessionId);
  wssUrl.searchParams.set("connectionUuid", connectionUUID);
  wssUrl.searchParams.set("attempt", attemptCount);
  wssUrl.searchParams.set("referer", window.location.href);
  wssUrl.searchParams.set("v", "20230330");

  logger.debug(LOGGER_PREFIX, "WS connection URL", wssUrl.toString());
  websocket = new WebSocket(wssUrl.toString());
  wssClients.push(websocket);

  websocket.addEventListener("open", () => {
    logger.info(LOGGER_PREFIX, "websocket open");
  });

  websocket.addEventListener("close", (reason) => {
    logger.info(LOGGER_PREFIX, "websocket close", { reason });
    onClose?.();

    // remove from the list of clients
    wssClients = wssClients.filter((client) => client !== websocket);
  });

  websocket.addEventListener("error", (event) => {
    logger.info(LOGGER_PREFIX, "websocket error", { event });
  });

  websocket.addEventListener(
    "message",
    onWebsocketMessage(websocket, onRequestStart)
  );
};

// exponential back off reconnect
const getReconnectTimeout = ({ wssConnectAttempts }) => {
  const timeout = Math.min(
    WSS_RECONNECT_TIMEOUT * Math.pow(2, wssConnectAttempts),
    WSS_RECONNECT_TIMEOUT
  );
  return timeout;
};

export const registerWebSocketListener = ({
  onRequestStart,
  workspaceId,
  getUserId,
  onUserIdChange,
}) => {
  let wssConnectAttempts = 0;
  let tryInterval = null;
  let initialUserId = getUserId();

  const reconnectIfUserIdChanged = () => {
    // check for userId every 3 seconds,
    // when it changes, reconnect the websocket
    const newUserId = getUserId();

    if (newUserId && newUserId !== initialUserId) {
      logger.log("User ID changed, reconnecting WS", {
        newUserId,
        initialUserId,
      });
      initialUserId = newUserId;
      connect();
    }
  };

  const connect = () => {
    initialUserId = getUserId();

    const checkUserIdInterval = setInterval(
      reconnectIfUserIdChanged,
      USER_ID_CHECK_INTERVAL
    );

    if (!initialUserId) {
      return;
    }

    const sessionId = `session-${workspaceId}-${initialUserId}`;

    logger.debug("connect() connecting");

    connectWebSocket({
      sessionId,
      attemptCount: wssConnectAttempts,
      onRequestStart,
      onClose: () => {
        clearInterval(checkUserIdInterval);

        // if document is hidden, don't reconnect
        if (document.hidden) {
          logger.debug("Reconnect after onClose handler, document is hidden");
          return;
        }

        // when connection closes, reopen it
        wssConnectAttempts += 1;
        if (wssConnectAttempts < WSS_MAX_RECONNECT_ATTEMPTS) {
          logger.debug("Reconnect after onClose handler", {
            wssConnectAttempts,
          });
          setTimeout(connect, getReconnectTimeout({ wssConnectAttempts }));
        }
      },
    });
  };

  let initialized = false;

  const tryConnect = () => {
    if (initialized) {
      logger.debug("tryConnect() already initialized");
      return;
    }

    logger.log("tryConnect() waiting for userId");
    initialized = true;

    const run = () => {
      tryInterval = retry(connect, getUserId, {
        attempts: USER_ID_CHECK_ATTEMPTS,
        intervalMS: USER_ID_CHECK_INTERVAL,
        failCallback: () => {
          logger.warn("Could not find user info, WSS connector will not work");
        },
      });
    };

    run();
  };

  const listener = {
    remove() {
      wssClients.forEach((client) => {
        // close all WebSockets
        if (client) {
          client.close();
        }
      });

      clearInterval(tryInterval);
    },
  };

  return [listener, tryConnect];
};
