/* eslint-disable no-console */
import {
  RAGE_CLICK_GENERATE_DELAY_MS,
  RAGE_CLICK_THRESHOLD_COUNT,
  RAGE_CLICK_THRESHOLD_INTERVAL,
  CLICK_ERROR_THRESHOLD_INTERVAL,
} from "./config";
import { debounce } from "./libs/debounce";
import { getTargetJSON } from "./libs/interceptors/interceptClicks";
import { getDateNow } from "./libs/getDateNow";
import {
  AutopilotErrorCallback,
  AutopilotErrorDetectorProps,
  ClickEvent,
} from "./types";

interface ErrorWithTimestamp extends Error {
  timestamp: number | undefined;
}

class ConsoleError implements ErrorWithTimestamp {
  name = "ConsoleError";
  message = "ConsoleError";
  timestamp: number | undefined;

  private _messageTokens: string[] = [];

  constructor(public args: any[]) {
    this._messageTokens = args;
    this.timestamp = getDateNow();
  }

  toString = () => {
    return this._messageTokens.join(" ");
  };

  toJSON = () => {
    return JSON.stringify(this._messageTokens);
  };
}

const IGNORED_ERROR_MESSAGES = [
  // list of partial strings
  "Navigation cancelled", // saw it in some framework when switching pages
  "AbortError", // common in Nuxt.js
  "TypeError: Cannot destructure property 'naturalHeight' of 'l' as it is null.", // 1982b96b
  '{"error":"Not found"}',
];

const removeIgnoredErrors = (err: ErrorWithTimestamp) =>
  !IGNORED_ERROR_MESSAGES.some((ignore) => err.message?.includes?.(ignore));

export class AutopilotErrorDetector {
  private _onError: AutopilotErrorCallback;
  private _clickBag: Map<HTMLElement, ClickEvent> = new Map();
  private _errorBag: Set<ErrorWithTimestamp> = new Set();
  private _originalConsoleError = console.error;

  constructor({ onError }: AutopilotErrorDetectorProps) {
    this._onError = onError;
  }

  stats = () => {
    return [
      "Clicks Bag: " +
        (this._clickBag.size > 0
          ? [...this._clickBag.keys()]
              .map((k) =>
                [k.tagName, this._clickBag.get(k)?.count ?? 0].join(" = ")
              )
              .join(", ")
          : "empty"),
      "Errors Bag: " +
        (this._errorBag.size > 0
          ? [...this._errorBag].map((k) => k.name).join(", ")
          : "empty"),
    ];
  };

  generateRageClick = debounce((clickEvent: ClickEvent) => {
    // calls onError with a rage-click error

    // DEPRECATED 2023-12-12

    // clickEvent.serializableTarget = getTargetJSON({
    //   target: clickEvent.targetNode,
    // });
    // delete clickEvent.targetNode;

    // this._onError({
    //   type: "rage-click",
    //   clickEvent,
    // });
  }, RAGE_CLICK_GENERATE_DELAY_MS);

  generateErrorClick = debounce((clickEvent: ClickEvent, errors: Error[]) => {
    // calls onError with an error-click error

    clickEvent.serializableTarget = getTargetJSON({
      target: clickEvent.targetNode,
    });
    delete clickEvent.targetNode;

    const jsErrors = errors.map((e: Error | any) => {
      if (e instanceof ConsoleError) {
        return {
          name: "ConsoleError",
          message: e.toString() !== '[object Object]' ? e.toString() : e.toJSON(),
          stack: "ConsoleError: console.error\n\t at console.error",
        };
      } else if (e instanceof Error) {
        return {
          name: e.name,
          message: e.message,
          stack: e.stack,
        };
      } else {
        const msg = e?.toString?.() ?? 'unknown error';
        return {
          name: "Error",
          message: msg,
          stack: `Error: ${msg}\n\t at unknown location`,
        };
      }
    });

    this._onError({
      type: "error-click",
      clickEvent,
      jsErrors,
      // networkError: errors.find((e) => !(e instanceof Error)),
    });
  }, RAGE_CLICK_GENERATE_DELAY_MS);

  handleClick = (event: MouseEvent) => {
    // add each click target to clickBag
    // if target is clicked more than 3 times in 2 seconds,
    // call generateRageClick

    const targetElement = event.target as HTMLElement;

    if (targetElement.id === "bugpilot-root") {
      // bugpilot-root is a shadow dom and it triggers
      // rage clicks (because we can't access the elements inside,
      // so all clicks are bubbled to the root)
      return;
    }

    if (targetElement.tagName === "BODY" || targetElement.tagName === "HTML") {
      return;
    }

    const tagName = targetElement.tagName;
    const outerHTML = targetElement.outerHTML?.toLowerCase() ?? "";

    if (
      tagName === "CANVAS" ||
      tagName === "P" ||
      tagName === "CODE" ||
      tagName === "TD" ||
      tagName === "TEXTAREA" ||
      // temporary to limit false positives when clicking on next/prev buttons
      tagName === "SVG" || // [TEMP] some icon-only buttons are SVGs
      outerHTML.includes("nav") || // pagination
      outerHTML.includes("next") || // pagination
      outerHTML.includes("prev") || // pagination
      outerHTML.includes("arrow") || // scroll arrows
      outerHTML.includes("picker") || // date time pickers
      // document has selected text
      !!window.getSelection()?.toString()
    ) {
      // Clicking on canvas is not a rage click because it is common
      // that you want to click on canvas multiple times, e.g. to draw.
      // Clicks on P can happen when user is trying to select multiple words, or
      // they are just bored and clicking on the text while reading.
      return;
    }

    const nextCount = (this._clickBag.get(targetElement)?.count ?? 0) + 1;

    const clickEvent = {
      targetNode: targetElement,
      clientX: event.clientX,
      clientY: event.clientY,
      timestamp: getDateNow(),
      count: nextCount,
    };

    this._clickBag.set(targetElement, clickEvent);

    if (nextCount >= RAGE_CLICK_THRESHOLD_COUNT) {
      // if there is a selected text in the page don't generate rage click
      const selectedText = window.getSelection()?.toString();

      if (selectedText) {
        return;
      }

      // this is a rage click
      this.generateRageClick(clickEvent);
    }

    // if there is an error up to CLICK_ERROR_THRESHOLD_INTERVAL seconds
    // after this click, call generateErrorClick

    setTimeout(() => {
      const errors = [...this._errorBag]
        .filter(removeIgnoredErrors)
        .filter(
          (err) =>
            err.timestamp &&
            err.timestamp >= clickEvent.timestamp &&
            err.timestamp <=
              clickEvent.timestamp + CLICK_ERROR_THRESHOLD_INTERVAL
        );

      if (errors.length > 0) {
        this.generateErrorClick(clickEvent, errors);
      }
    }, CLICK_ERROR_THRESHOLD_INTERVAL + 100);

    setTimeout(() => {
      this._clickBag.delete(targetElement);
    }, RAGE_CLICK_THRESHOLD_INTERVAL);
  };

  _onConsoleError = (event: ErrorEvent) => {
    const err = event.error as ErrorWithTimestamp;

    if (err == null) {
      return;
    }

    err.timestamp = getDateNow();
    this._errorBag.add(err);
  };

  _onUnhandledRejection = (event: PromiseRejectionEvent) => {
    const err = event.reason as ErrorWithTimestamp;

    if (err == null) {
      return;
    }

    err.timestamp = getDateNow();
    this._errorBag.add(err);

    setTimeout(() => {
      this._errorBag.delete(err);
    }, CLICK_ERROR_THRESHOLD_INTERVAL * 2);
  };

  _onWindowError = (event: ErrorEvent) => {
    const err = event.error as ErrorWithTimestamp;

    if (err == null) {
      return;
    }

    err.timestamp = getDateNow();
    this._errorBag.add(err);

    setTimeout(() => {
      this._errorBag.delete(err);
    }, CLICK_ERROR_THRESHOLD_INTERVAL * 2);
  };

  start = () => {
    // Listen for clicks
    //
    window.addEventListener("click", this.handleClick, {
      capture: true,
      passive: true,
    });

    // Listen for Unhandled promise rejections
    //
    window.addEventListener("unhandledrejection", this._onUnhandledRejection);
    window.addEventListener("error", this._onWindowError);

    // Listen for calls to console.error
    //
    const addError = (args: any[]) => {
      try {
        this._errorBag.add(new ConsoleError(args));

        setTimeout(() => {
          this._errorBag.delete(new ConsoleError(args));
        }, CLICK_ERROR_THRESHOLD_INTERVAL * 2);
      } catch (e) {
        this._originalConsoleError(e);
      }
    };

    console.error = ((method) => {
      return (...args: any[]) => {
        addError(args);
        return method(...args);
      };
    })(this._originalConsoleError);
  };

  stop = () => {
    window.removeEventListener("click", this.handleClick, {
      capture: true,
    });
    window.removeEventListener(
      "unhandledrejection",
      this._onUnhandledRejection
    );
    window.removeEventListener("error", this._onWindowError);
    console.error = this._originalConsoleError;
  };
}
