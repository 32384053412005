import { shouldInterceptBody } from "./_settings";
import { getDateNow } from "../getDateNow";

const originalXHROpen = XMLHttpRequest.prototype.open;

export function interceptXMLHTTPRequest(addActivity, { onError }) {
  XMLHttpRequest.prototype.open = function (...args) {
    const url = args[1];
    const method = args[0].toUpperCase();
    let activity;

    if (!url || typeof url.match !== "function") {
      // Todo: support for url of type URL
      // same as we did in fetch()
      return originalXHROpen.apply(this, arguments);
    }

    if (url.match(/\.woff|\.woff2|\.eot|\.ttf|\.otf|\.svg/)) {
      return originalXHROpen.apply(this, arguments);
    }

    this.addEventListener("loadend", (e) => {
      const xhr = e.target;
      const allResponseHeaders = xhr.getAllResponseHeaders();

      // A string representing all the response's headers (except those whose field name is Set-Cookie) separated by CRL
      // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/getAllResponseHeaders
      const headers = allResponseHeaders
        .split("\r\n")
        .filter(Boolean)
        .reduce((total, current) => {
          const [name, value] = current.split(": ");

          if (
            name.toLowerCase() === "authorization" ||
            name.toLowerCase() === "x-api-key"
          ) {
            total[name] = "********";
          } else if (value.includes("Bearer")) {
            total[name] = "********";
          } else {
            total[name] = value;
          }

          return total;
        }, {});

      let responseBody = shouldInterceptBody({
        url,
        contentType: headers["content-type"],
      })
        ? xhr.response
        : null;

      addActivity({
        sourceRecordingId: activity?.id,
        timestamp: getDateNow(),
        type: "fetchResponse",
        data: {
          // https://developer.mozilla.org/en-US/docs/Web/API/Response/ok
          ok: xhr.status >= 200 && xhr.status <= 299,
          url: xhr.responseURL,
          status: xhr.status,
          headers,
          body: responseBody,
        },
      });

      if (xhr.status < 400) {
        return;
      }

      onError({
        type: "fetch-error",
        url,
        method,
      });
    });

    const originalXHRSend = this.send;
    const originalXHRSetRequestHeader = this.setRequestHeader;

    const headers = {};
    this.setRequestHeader = (name, value) => {
      headers[name] = value;
      return originalXHRSetRequestHeader.call(this, name, value);
    };

    this.send = (body) => {
      activity = addActivity({
        timestamp: getDateNow(),
        type: "fetch",
        data: {
          initiator: "XMLHttpRequest",
          url,
          args: [
            {
              method,
              headers,
              body,
            },
          ],
        },
      });

      return originalXHRSend.call(this, body);
    };

    return originalXHROpen.apply(this, arguments);
  };

  return {
    stop() {
      XMLHttpRequest.prototype.open = originalXHROpen;
    },
  };
}
