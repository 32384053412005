import { asSerializableValue } from "../../../api";
import { getDateNow } from "../getDateNow";

const originalConsole = {
  ...console,
};

const INSTRUMENT_METHODS_LIST = ["log", "warn", "error", "info", "debug"];

export function interceptConsole(addActivity) {
  const replaceConsoleMethod = (methodName) => {
    const originalMethod = originalConsole[methodName];
    // eslint-disable-next-line no-console
    console[methodName] = (...args) => {
      const ret = originalMethod(...args);

      try {
        const safeArgs = args.map((arg) => {
          return asSerializableValue(arg);
        });

        addActivity({
          type: "console",
          timestamp: getDateNow(),
          data: {
            method: methodName,
            args: safeArgs,
          },
        });
        // eslint-disable-next-line no-empty
      } catch (error) {}

      return ret;
    };
  };

  INSTRUMENT_METHODS_LIST.forEach(replaceConsoleMethod);

  return {
    stop() {
      window.console = originalConsole;
    },
  };
}
