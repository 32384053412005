const ADOPTO_DEFAULT_EXCLUDED_CLASSES = [
  "adopto-block",
  "bugpilot-block",
  "StripeElement",
];

export const removeAdoptoKeys = (key) => key !== "Adopto::Report";
const escapeRegExp = (string) => string.replace(/[-.+?^${}()|[\]\\]/g, "\\$&"); // Source: https://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex/6969486#6969486
const globToRegexBlock = (c) => `(${escapeRegExp(c).replace("*", ".+")})`;

export const getExcludedClassNamesRegex = ({ company }) => {
  if (!company) {
    throw new Error("Adopto.getExcludedClassNamesRegex: company is required");
  }

  const workspaceExcludedClassNames = company.excludedClassNames || [];
  const pattern = [
    ...ADOPTO_DEFAULT_EXCLUDED_CLASSES,
    ...workspaceExcludedClassNames,
  ]
    .map(globToRegexBlock)
    .join("|");

  const re = new RegExp(`^${pattern}$`);
  return re;
};

export const shouldExcludeNode = ({ node, blockClass }) => {
  // return true if node or one of its parents
  // match the exclusion regexp (blockClass)
  // (blockClass is the value returned by invoking getExcludedClassNamesRegex)

  while (node != null) {
    const domClassList = node.classList || [];
    const classNames = [...domClassList]; // converts to an actual array

    if (classNames.some((className) => className.match(blockClass))) {
      return true;
    }
    node = node.parentNode;
  }

  return false;
};
