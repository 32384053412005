// import logger from "../../logger";

const retry = (
  callback,
  conditionFn,
  { intervalMS = 1000, attempts = 10, failCallback } = {}
) => {
  if (conditionFn()) {
    callback();
    return;
  }

  let counter = 0;
  let interval = setInterval(() => {
    // logger.debug("retry()", { callback, condition: conditionFn, counter });

    if (counter > attempts) {
      clearInterval(interval);
      failCallback?.();
      return;
    }

    if (conditionFn()) {
      clearInterval(interval);
      callback();
      return;
    }

    counter++;
  }, intervalMS);

  return interval;
};

export default retry;
