import nanoid from "./libs/uuid";
import { interceptNavigation } from "./libs/interceptors/interceptNavigation";
import { interceptClicks } from "./libs/interceptors/interceptClicks";
import { interceptXMLHTTPRequest } from "./libs/interceptors/interceptXMLHTTPRequest";
import { interceptFetch } from "./libs/interceptors/interceptFetch";
import { interceptConsole } from "./libs/interceptors/interceptConsole";
import { interceptResources } from "./libs/interceptors/interceptResources";
import { interceptErrors } from "./libs/interceptors/interceptErrors";
import { interceptScreenCapture } from "./libs/interceptors/interceptScreenCapture";
import { interceptVisibility } from "./libs/interceptors/interceptVisibility";

import logger from "../logger";
import { getExcludedClassNamesRegex } from "./libs/exclusionUtils";
import compose from "./libs/compose";
import maskUUIDs from "./libs/maskUUIDs";
import maskJWT from "./libs/maskJWT";
import { getDateNow } from "./libs/getDateNow";
import { SESSION_SAVE_INTERVAL_MS, UploadService } from "./UploadService";
import {
  BugpilotConfig,
  ReportConstructorOpts,
  Interceptor,
  WorkspaceSettings,
  SessionStatus,
  ReportData,
} from "./types";
import { MAX_RECORDING_DURATION_MS } from "./config";
import { removeFonts } from "./libs/removeFonts";
import { mergeDeep } from "./libs/mergeDeep";

const withTimestamp = (rec) => ({
  ...rec,
  _pushTimestamp: getDateNow(),
});

const withId = (rec) => {
  const id = nanoid();
  return { ...rec, id };
};

export class Report {
  company: WorkspaceSettings;
  id: string;
  reportData: Partial<ReportData> | null = null;
  sessionStatus: SessionStatus = "undecided";
  uploadService: UploadService;

  private config: BugpilotConfig;
  private interceptors: Interceptor[] = [];
  private onStop: Function;
  private onUpload: Function;
  private onError: Function;
  public anonymousId: string;
  private get sessionId(): string | null {
    const sessionId = sessionStorage.getItem("Bugpilot::sessionId");

    if (sessionId) {
      return sessionId;
    }

    const newSessionId = `session-${nanoid()}`;
    sessionStorage.setItem("Bugpilot::sessionId", newSessionId);
    return newSessionId;
  }

  // == startedAt ==
  // session start timestamp
  //

  public get uploadStartedAt() {
    return Number(sessionStorage.getItem("Bugpilot::sessionStartedAt") || 0);
  }

  private set uploadStartedAt(value: number | null) {
    if (!value) {
      sessionStorage.removeItem("Bugpilot::sessionStartedAt");
      return;
    }

    sessionStorage.setItem("Bugpilot::sessionStartedAt", value.toString());
  }

  //

  constructor({
    id,
    config,
    onUpload,
    onStop,
    onError,
    anonymousId,
  }: ReportConstructorOpts) {
    logger.debug("Report.constructor");

    if (!id) {
      throw new Error("Report ID is required");
    }

    if (!config || !config.workspaceSettings || !config.workspaceId) {
      throw new Error("Config and WorkspaceSettings are required");
    }

    this.config = config;
    this.company = config.workspaceSettings;
    this.onUpload = onUpload;
    this.onStop = onStop;
    this.onError = onError;
    this.anonymousId = anonymousId;

    this.reportData = {
      sessionId: this.sessionId,
      user: {
        anonymousId: this.anonymousId,
        ...(this.config.user || {}),
      },
    };
    this.interceptors = [];

    this.id = id;
    this.uploadService = new UploadService(id, config.workspaceId, () => {
      // stop report when websocket disconnects
      this.stop();
    });
  }

  updateReportData(reportData: Partial<ReportData>) {
    mergeDeep(this.reportData, reportData);

    if (this.sessionStatus === "uploading") {
      this.uploadService.patchReport(this);
      this.onUpload("report");
    }
  }

  addActivity = (recording) => {
    logger.debug("Report.addActivity", { recording });

    if (!this.company?.shouldRecordUUIDs) {
      try {
        recording.data = JSON.parse(
          compose(maskUUIDs, maskJWT)(JSON.stringify(recording.data))
        );
      } catch (e) {
        logger.warn("Error masking UUIDs in activity data", e);
      }
    }

    const activity = compose(withTimestamp, withId)(recording);

    // Add the event to the upload queue,
    // so it can be sent to the server
    const event = {
      eventType: "activities",
      ...activity,
    };
    this.uploadService.enqueue(event, this.sessionStatus === "uploading");

    return activity;
  };

  addRRWebEvent = (recording, isCheckout) => {
    if (Array.isArray(recording?.data?.adds)) {
      recording.data.adds = recording.data.adds.map((item) => ({
        ...item,
        rule: removeFonts(item.rule),
      }));
    }

    // Add the event to the upload queue
    // so it can be sent to the server
    const event = {
      eventType: "recordings",
      isCheckout: isCheckout,
      ...recording,
    };
    this.uploadService.enqueue(event, this.sessionStatus === "uploading");

    return recording;
  };

  start() {
    logger.info("Report.start", this.id);

    const blockClass = getExcludedClassNamesRegex({ company: this.company });

    this.interceptors.push(
      interceptScreenCapture(this.addRRWebEvent, {
        blockClass: getExcludedClassNamesRegex({ company: this.company }),
        flags: this.config?.workspaceSettings?.flags || {},
        piiRemovalMode: this.company?.piiRemovalMode,
      })
    );

    this.interceptors.push(interceptResources(this.addActivity));

    // Starts console recording and error redirecting
    this.interceptors.push(interceptConsole(this.addActivity));
    this.interceptors.push(interceptErrors(this.addActivity));

    // Starts intercepting fetch and XMLHTTPRequests
    this.interceptors.push(
      interceptFetch(this.addActivity, {
        onError: this.onError,
      })
    );
    this.interceptors.push(
      interceptXMLHTTPRequest(this.addActivity, { onError: this.onError })
    );

    // Start intercepting clicks and capturing element
    // screenshots
    this.interceptors.push(
      interceptClicks(this.addActivity, {
        blockClass,
        piiRemovalMode: this.company?.piiRemovalMode,
      })
    );

    // Starts intercepting browser navigation events
    this.interceptors.push(interceptNavigation(this.addActivity));

    // Page visibility
    this.interceptors.push(interceptVisibility(this.addActivity));

    const uploadInterval = setInterval(() => {
      this.sessionStatus = this._getSessionStatus();
      logger.debug("Report.saveSession", { shouldUpload: this.sessionStatus });

      if (
        this.sessionStatus === "undecided" ||
        this.sessionStatus === "discarded"
      ) {
        logger.log("This session is undecided/discarded, nothing to do");
        return;
      }

      // if this session is marked for upload, then
      // we should save it to the server. We check the condition
      // every time to avoid uploading content unnecessarily
      this.uploadService.uploadQueue();
      this.onUpload("events");

      if (this.sessionStatus === "terminated") {
        logger.log("Session terminated, stopping Report");
        clearInterval(uploadInterval);
        this.stop();
        return;
      }

      if (!this.uploadStartedAt) {
        this.uploadStartedAt = getDateNow();
      }
    }, SESSION_SAVE_INTERVAL_MS);
  }

  stop() {
    logger.info("Report.stop called", this.id);

    this.interceptors.forEach((interceptor) => interceptor.stop());
    this.interceptors = [];

    this.sessionStatus = "terminated";
    this.uploadStartedAt = null;

    this.onStop();
  }

  private _getSessionStatus(): SessionStatus {
    const workspaceSettings = this.config.workspaceSettings;

    if (!workspaceSettings) {
      return "undecided";
    }

    const overQuota = workspaceSettings.overQuota;

    // === High priority conditions ===
    // - User is over quota
    // - Recording is disabled in workspace config
    //

    //
    // TEMPORARILY DISABLED
    //

    // if (overQuota) {
    //   logger.log("Workspace is over quota, this session will not be recorded");

    //   if (!this.reportData?.metadata?.isOverQuota) {
    //     this.updateReportData({
    //       metadata: {
    //         isOverQuota: true,
    //       },
    //     });
    //   }

    //   return "discarded";
    // }

    // === Support for multi page ===
    // Will keep recording until the session max duration is reached
    //

    if (this.sessionStatus === "uploading") {
      if (
        this.uploadStartedAt &&
        getDateNow() > this.uploadStartedAt + MAX_RECORDING_DURATION_MS
      ) {
        // if the session is being recorded, but we reached the maximum duration,
        // then we stop recording and we remove the flag
        logger.log(
          "Session is longer than max duration, no more events will be sent and session will end"
        );
        return "terminated";
      }

      // if the session is being uploaded, we keep uploading it until
      // the session timeout is reached.
      return "uploading";
    }

    return "discarded";
  }

  public async requestUpload() {
    if (this.sessionStatus === "uploading") {
      return;
    }

    await this.uploadService.postReport(this);
    this.onUpload("report");

    this.uploadService.prepareForEventsUpload();
    this.sessionStatus = "uploading";
  }
}
