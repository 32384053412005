// Handles uploading Reports and Session Data to the server
//

import { Event, ReportData } from "./types";
import logger from "../logger";
import { Report } from "./Report";
import makeReportPayload from "./libs/makeReportPayload";
import { patchReport, postReport } from "../api";
import { getDateNow } from "./libs/getDateNow";
// import stringify from "./libs/safeStringify";

const stringify = JSON.stringify;

export const SESSION_SAVE_INTERVAL_MS = 5 * 1000;
const CONSOLE_LOGS_INIT_THRESHOLD_MS = 5 * 60 * 1000;

export class UploadService {
  private wasUploaded = false;
  private queuedForUpload: Event[] = [];
  private id: string;
  private workspaceId: string;
  private ws: WebSocket | null = null;
  private onDisconnect: () => void;

  private lastReportUpdateTimestamp: number = 0;
  private lastEventsPostTimestamp: number = 0;

  constructor(id: string, workspaceId: string, onDisconnect: () => void) {
    this.id = id;
    this.workspaceId = workspaceId;
    this.onDisconnect = onDisconnect;
  }

  stats = () => {
    const checkoutsCount = this.queuedForUpload.filter(
      (event) => event.eventType === "recordings" && event.isCheckout
    ).length;

    const earliestRecordingTimestamp = this.queuedForUpload
      .filter((event) => event.eventType === "recordings")
      .map((event) => event.timestamp)[0];

    const earliestActivityTimestamp = this.queuedForUpload
      .filter((event) => event.eventType === "activities")
      .map((event) => event.timestamp)[0];

    return [
      "Websocket" +
        (this.ws?.readyState === WebSocket.OPEN
          ? " connected"
          : " disconnected"),
      this.queuedForUpload.length === 0 ? "**Queue empty**" : "(Events queued)",
      "Uploading: " + (this.wasUploaded ? "yes" : "no"),
      "Queued events: " + this.queuedForUpload.length,
      "Checkouts: " + checkoutsCount,
      "Earliest recording timestamp: " +
        new Date(earliestRecordingTimestamp).toUTCString(),
      "Earliest activity timestamp: " +
        (earliestActivityTimestamp
          ? new Date(earliestActivityTimestamp).toUTCString()
          : "no"),
      "Last report post/patch: " +
        (this.lastReportUpdateTimestamp
          ? new Date(this.lastReportUpdateTimestamp).toUTCString()
          : "never"),
      "Last events post: " +
        (this.lastEventsPostTimestamp
          ? new Date(this.lastEventsPostTimestamp).toUTCString()
          : "never"),
    ];
  };

  private cleanupOldCheckouts = () => {
    // find the last-to-one event with isCheckout=true
    // and remove everything before it
    const lastCheckoutIndex = this.queuedForUpload
      .map((event, index) => ({
        isCheckout: event.eventType === "recordings" ? event.isCheckout : false,
        index,
      }))
      .filter((e) => e.isCheckout)
      .reverse()?.[1]?.index; // index of the 2nd-to-last checkout

    if (lastCheckoutIndex) {
      logger.debug(
        "[UploadService] Cleaning up before index",
        lastCheckoutIndex
      );

      this.queuedForUpload = this.queuedForUpload.filter(
        (event, index) =>
          event.eventType !== "recordings" || index >= lastCheckoutIndex
      );
    }

    logger.debug(
      "[UploadService] Event count after cleanup",
      this.queuedForUpload.length
    );
  };

  enqueue = (event: Event, uploadImmediately: boolean = false) => {
    logger.debug("[UploadService] Enqueued event", event);

    if (uploadImmediately && this.ws?.readyState === WebSocket.OPEN) {
      this.ws?.send(stringify([event]));
    } else {
      this.queuedForUpload.push(event);
    }

    if (event.eventType === "recordings" && !this.wasUploaded) {
      this.cleanupOldCheckouts();
    }
  };

  /**
   * Updates an existing session report with the latest content. This is called
   * every SESSION_SAVE_INTERVAL_MS and it stores activities and rrweb events
   */
  uploadQueue = async () => {
    if (this.queuedForUpload.length === 0) {
      return;
    }

    if (!this.ws || this.ws.readyState !== WebSocket.OPEN) {
      logger.warn("WebSocket is not open, skipping upload this time");
      return;
    }

    this._safeUploadQueue();

    this.wasUploaded = true;
    this.lastEventsPostTimestamp = getDateNow();
  };

  private _safeUploadQueue = () => {
    const _upload = (event: Event) => {
      if (!this.ws || this.ws.readyState !== WebSocket.OPEN) {
        logger.warn(
          "WebSocket is not open, skipping upload this time; events will be lost."
        );
        return;
      }

      try {
        this.ws?.send(stringify([event]));
      } catch (e) {
        logger.warn(
          "_safeUploadQueue(): Skpping event. Failed to stringify or send event",
          event,
          "with error",
          e.message
        );
      }
    };

    this.queuedForUpload.forEach(_upload);
    this.queuedForUpload = [];
  };

  private _connectWs = () => {
    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
      logger.debug("[UploadService] WebSocket already open");
      return;
    }

    const wsUrl = new URL("wss://events-stream.bugpilot.io");
    wsUrl.searchParams.set("reportId", this.id);
    wsUrl.searchParams.set("workspaceId", this.workspaceId);
    this.ws = new WebSocket(wsUrl);

    let shouldReconnect = true;

    this.ws.addEventListener("open", () => {
      if (this.queuedForUpload.length > 0) {
        this._safeUploadQueue();
      }
    });

    this.ws.addEventListener("message", (msg) => {
      if (msg.data === "close") {
        shouldReconnect = false;
        this.ws?.close();
      }
    });

    this.ws.onclose = () => {
      logger.debug("[UploadService] WebSocket closed");

      if (shouldReconnect) {
        logger.debug("[UploadService] Reconnecting WebSocket");
        this._connectWs();
      } else {
        this.onDisconnect();
      }
    };
  };

  prepareForEventsUpload = async () => {
    // Take console logs only for last 5 minutes
    this.queuedForUpload = this.queuedForUpload.filter(
      ({ eventType, type, timestamp }) =>
        eventType !== "activities" ||
        type !== "console" ||
        timestamp >= getDateNow() - CONSOLE_LOGS_INIT_THRESHOLD_MS
    );

    // connect to the WebSocket after the report is saved
    this._connectWs();
  };

  postReport = async (report: Report) => {
    const reportData: ReportData = await makeReportPayload({ report });

    try {
      await postReport(reportData);
      this.lastReportUpdateTimestamp = getDateNow();
    } catch (e) {
      logger.error("Failed to post report", e);
    }
  };

  patchReport = async (report: Report) => {
    const reportData: ReportData = await makeReportPayload({ report });

    try {
      await patchReport(reportData.id, reportData);
      this.lastReportUpdateTimestamp = getDateNow();
    } catch (e) {
      logger.error("Failed to patch report", e);
    }
  };
}
