import Bugpilot from "./Bugpilot";
import { Report } from "./Report";

export class Debugger {
  public enabled: boolean;
  public lastSaveTimestamp: number = 0;
  public report: Report | null = null;
  private bugpilot: Bugpilot;

  constructor(bugpilot: Bugpilot) {
    this.bugpilot = bugpilot;
    this.enabled = window.location.hash.includes("bugpilot-debug");
    this.show();
  }

  private show() {
    // remove old debugger
    const oldDebugger = document.getElementById("bugpilot-debugger");
    if (oldDebugger) {
      oldDebugger.remove();
    }

    if (!this.enabled) {
      return;
    }

    const div = document.createElement("div");
    div.id = "bugpilot-debugger";
    div.style.position = "fixed";
    div.style.top = "0";
    div.style.right = "0";
    div.style.zIndex = "99999";
    div.style.backgroundColor = "#ff000070";
    div.style.color = "white";
    div.style.padding = "10px";
    div.style.fontSize = "10px";
    div.style.width = "320px";
    // div.style.pointerEvents = "none";
    div.innerText = "Bugpilot Debugger Enabled";

    div.appendChild(document.createElement("br"));

    // reportId
    const p1 = document.createElement("span");
    p1.innerText = "Report ID: " + sessionStorage.getItem("Bugpilot::reportId");
    div.appendChild(p1);
    div.appendChild(document.createElement("br"));

    // sessionStartedAt
    const sessionStartedAt = sessionStorage.getItem(
      "Bugpilot::sessionStartedAt"
    );
    const p2 = document.createElement("span");
    p2.innerText =
      "Upload Started At: " +
      (sessionStartedAt
        ? new Date(Number(sessionStartedAt) || 0).toUTCString()
        : "not started");
    div.appendChild(p2);
    div.appendChild(document.createElement("br"));

    // lastSaveTimestamp
    const p3 = document.createElement("span");
    p3.innerText =
      "Last Upload Timestamp: " +
      (this.lastSaveTimestamp
        ? new Date(this.lastSaveTimestamp).toUTCString()
        : "never");
    div.appendChild(p3);
    div.appendChild(document.createElement("br"));

    // report.getSessionStatus
    const p4 = document.createElement("span");
    p4.innerText =
      "Session Status: " + (this.report ? this.report.sessionStatus : "n/a");
    div.appendChild(p4);
    div.appendChild(document.createElement("br"));

    document.body.appendChild(div);

    // report.user?.id
    const p5 = document.createElement("span");
    p5.innerText = "User ID: " + (this.report?.reportData?.user?.id ?? "n/a");
    div.appendChild(p5);
    div.appendChild(document.createElement("br"));

    // show uploadservice stats
    const p6 = document.createElement("span");
    p6.innerHTML =
      "<br />== UploadService Stats ==<br /> - " +
      (this.report?.uploadService?.stats()?.join("<br /> - ") ?? "Queue Empty");
    div.appendChild(p6);
    div.appendChild(document.createElement("br"));

    // user
    const p7 = document.createElement("p");
    p7.style.whiteSpace = "pre-wrap";
    p7.style.wordBreak = "break-all";
    p7.innerHTML =
      "<br />== User ==<br />" +
      JSON.stringify(this.report?.reportData?.user ?? "n/a");
    div.appendChild(p7);

    // metadata
    const p8 = document.createElement("p");
    p8.style.whiteSpace = "pre-wrap";
    p8.style.wordBreak = "break-all";
    p8.innerHTML =
      "== Metadata ==<br />" +
      JSON.stringify(this.report?.reportData?.metadata ?? "n/a");
    div.appendChild(p8);

    // metadata
    const p8b = document.createElement("p");
    p8b.style.whiteSpace = "pre-wrap";
    p8b.style.wordBreak = "break-all";
    p8b.innerHTML =
      "== Errors ==<br />" +
      (this.report?.reportData?.autopilotErrors
        ?.map((e) => e.type)
        .join(", ") ?? "no");
    div.appendChild(p8b);

    // show detector stats
    const p9 = document.createElement("p");
    p9.innerHTML =
      "== Detector ==<br /> - " +
      (this.bugpilot?.detector?.stats()?.join("<br /> - ") ??
        "Initializing...");
    div.appendChild(p9);

    // report link
    const p10 = document.createElement("a");
    p10.style.color = "white";
    p10.href =
      (window.location.hostname === "localhost"
        ? "http://localhost:3000"
        : "https://dash.bugpilot.io") +
      "/report/" +
      this.report?.id +
      "?force=1";
    p10.target = "_blank";
    p10.innerText = "Open Report";
    div.appendChild(p10);

    // update every 1s
    setTimeout(() => {
      this.show();
    }, 1000);
  }
}
