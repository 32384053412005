import { getDateNow } from "../getDateNow";

export function interceptVisibility(addActivity) {
  const onHidden = () => {
    addActivity({
      type: "visibility",
      timestamp: getDateNow(),
      data: {
        state: "hidden",
      },
    });
  };

  const onVisible = () => {
    addActivity({
      type: "visibility",
      timestamp: getDateNow(),
      data: {
        state: "visible",
      },
    });
  };

  const onChange = () => {
    if (document.visibilityState === "hidden") {
      onHidden();
    } else {
      onVisible();
    }
  };

  document.addEventListener("visibilitychange", onChange, false);

  return {
    stop() {
      document.removeEventListener("visibilitychange", onChange, false);
    },
  };
}
