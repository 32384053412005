const INTERCEPT_CONTENT_TYPES = [
  "text/html",
  "application/json",
  "application/xml",
  "application/xhtml+xml",
  "text/xml",
  "text/plain",
  "text/css",
  "text/javascript",
  "application/x-www-form-urlencoded",
];

export const shouldInterceptBody = ({ url, contentType }) => {
  let urlString = url ? (typeof url === "string" ? url : url.toString()) : null;

  if (typeof contentType !== "string") {
    return false;
  }

  const denylistHosts = ["paypal", "stripe", "braintree", "cognito", "auth0"];

  const urlCheck =
    urlString && !denylistHosts.some((host) => urlString.includes(host));

  const contentTypeCheck =
    contentType &&
    INTERCEPT_CONTENT_TYPES.some((type) => contentType.includes(type));

  return contentTypeCheck && urlCheck;
};

export const removeDigitsFromText = (tagName, val) => {
  if (!tagName || !val) {
    return val;
  }

  if (["button", "a"].includes(tagName.toLowerCase())) {
    return val;
  }

  if (typeof val !== "string") {
    return val;
  }

  return val.replace(/\d/g, "*");
};

const EXCLUDE_ATTRIBUTES = ["value"];

export const getNodeOpeningTag = (target) => {
  if (!target) {
    return "";
  }

  const tagName = target.tagName.toLowerCase();
  const attrs = [...target.attributes];
  const attrString = attrs
    .filter((attr) => !EXCLUDE_ATTRIBUTES.includes(attr.name))
    .map((attr) => `${attr.name}="${attr.value?.replace?.('"', '\\"')}"`);

  const openingTag = `<${tagName} ${attrString.join(" ")}>`;

  if (target.hasChildNodes()) {
    return `${openingTag}\n(${target.childNodes.length} children)\n</${tagName}>`;
  }

  return `${openingTag.replace(/>$/, " />")}`;
};

export const getDataAttrs = (target) => {
  if (!target) {
    return {};
  }

  const names = target.getAttributeNames();
  const dataAttrs = names.reduce((acc, attr) => {
    if (attr.startsWith("data-") || attr.startsWith("aria-")) {
      acc[attr] = target.getAttribute(attr);
    }
    return acc;
  }, {});

  return dataAttrs;
};
