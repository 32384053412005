import { getDateNow } from "../getDateNow";

export function interceptNavigation(addActivity) {
  let currentHref = null;

  const checkLocation = () => {
    const newHref = window.location.href;
    if (currentHref === newHref) {
      return;
    }

    addActivity({
      type: "navigate",
      timestamp: getDateNow(),
      data: {
        initial: false,
        oldUrl: currentHref,
        url: newHref,
      },
    });

    currentHref = newHref;
  };

  checkLocation(); // initial check (needed because setInterval invokes on the trailing edge)
  const interval = setInterval(checkLocation, 500);
  window.addEventListener("beforeunload", checkLocation);

  return {
    stop() {
      clearInterval(interval);
      window.removeEventListener("beforeunload", checkLocation);
    },
  };
}
