import logger from "./logger";

// keep track of the original fetch function
// so that Adopto's logs do not get logged
const originalFetch = window.fetch;
const config = window.AdoptoConfig || window.BugpilotConfig || {};

const DOMAIN_NAME = "bugpilot.io";
const API_URL = config.workspaceSettings?.apiCustomDomainName
  ? `https://${config.workspaceSettings.apiCustomDomainName}`
  : `https://api.${DOMAIN_NAME}`;
const API_BASE_URL = `${API_URL}/v1`;
const DEFAULT_HEADERS = {
  "Content-Type": "application/json",
};
const queryString = `?scriptVersion=${process.env.REACT_APP_VERSION}&v=20230330`;

export const extractErrorProperties = (e) => {
  if (!e) {
    return "[null]";
  }

  return {
    // Error Events have a nested error property:
    // https://developer.mozilla.org/en-US/docs/Web/API/ErrorEvent
    isErrorEvent: e instanceof ErrorEvent,
    errorEventError: extractErrorProperties(e.error),
    filename: e.filename,
    lineno: e.lineno,
    colno: e.colno,

    // common properties:
    name: e.name,
    message: e.message,
    stack: e.stack,
    value: Error.prototype.toString.call(e),
  };
};

export const asSerializableValue = (item) => {
  if (item === null || typeof item === "undefined" || item === undefined) {
    // avoid errors accessing null properties below
    return item;
  }

  if (typeof item === "number" || typeof item === "boolean") {
    // return simple types as is
    return item;
  }

  // handle special types of values:
  //

  if (typeof item === "symbol") {
    // symbols cannot be stringified
    return "Symbol()";
  }

  if (item === document || item === document.body || item === window) {
    // avoid stringifying huge DOM elements
    return "[Object HTMLDocument]";
  }

  // Weird DOM Types that cannot be stringified
  //

  if (item instanceof HTMLElement) {
    // stringify HTMLElement to prevent circular references
    return item.innerHTML?.substring(0, 100) || "[HTMLElement]";
  }

  if (item instanceof NodeList) {
    // stringify NodeList to prevent circular references
    return `[NodeList ${item.length}]`;
  }

  if (item instanceof HTMLCollection) {
    // stringify HTMLCollection to prevent circular references
    return `[HTMLCollection ${item.length}]`;
  }

  if (item instanceof File) {
    // stringify File to prevent circular references
    return `[File ${item.name}]`;
  }

  if (item instanceof FileList) {
    // stringify FileList to prevent circular references
    return `[FileList ${item.length}]`;
  }

  if (item instanceof FormData) {
    // stringify FormData to prevent circular references
    return `[FormData ${item.size}]`;
  }

  if (item instanceof Node) {
    // stringify Node to prevent circular references
    return `[Node ${item.nodeName}]`;
  }

  if (item instanceof Event) {
    // stringify Event to prevent circular references
    return `[Event ${item.type}]`;
  }

  if (item instanceof EventTarget) {
    // stringify EventTarget to prevent circular references
    return `[EventTarget ${item.nodeName}]`;
  }

  if (item instanceof DOMException) {
    // stringify DOMException to prevent circular references
    return `[DOMException ${item.message}]`;
  }

  //

  if (item instanceof Error || item instanceof ErrorEvent) {
    // we want to wrap errors with our own metadata and structure
    return extractErrorProperties(item);
  }

  if (typeof item === "object" && item?.__BugpilotWrapError__) {
    // the interceptError function wrap the error in an object,
    // so that we can catch it here and log it properly
    return extractErrorProperties(item.e);
  }

  if (typeof item === "function") {
    return item.name || item.toString();
  }

  if (Object.prototype.toString.call(item) === "[object RegExp]") {
    return RegExp.prototype.toString.call(item);
  }

  if (Object.prototype.toString.call(item) === "[object Date]") {
    return Date.prototype.toISOString.call(item);
  }

  return item;
};

export const asSafeJsonReplacer = (key, value) => {
  // stringify but return empty string for HTMLElement
  if (key === "") {
    return value;
  }
  // developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#the_replacer_parameter
  return asSerializableValue(value);
};

export const postReport = async (reportProperties) => {
  logger.debug("postReport", reportProperties);
  const body = JSON.stringify(reportProperties);

  const res = await originalFetch(`${API_BASE_URL}/reports${queryString}`, {
    method: "POST",
    headers: {
      ...DEFAULT_HEADERS,
    },
    body,
  });

  if (!res.ok) {
    logger.error("Failed to fetch postReport", res);
    throw new Error("Failed to fetch postReport");
  }
};

export const patchReport = async (id, report) => {
  logger.debug("patchReport", id, report);
  const body = JSON.stringify(report);

  let res = await originalFetch(`${API_BASE_URL}/reports/${id}${queryString}`, {
    method: "PATCH",
    headers: {
      ...DEFAULT_HEADERS,
    },
    body,
  });

  if (res.status === 404) {
    res = await originalFetch(`${API_BASE_URL}/reports${queryString}`, {
      method: "POST",
      headers: {
        ...DEFAULT_HEADERS,
      },
      body,
    });
  }

  if (!res.ok) {
    logger.error("Failed to fetch patchReport", res);
    return null;
  }
};

export const uploadReportEvents = async ({
  type,
  reportId,
  events,
  onError,
}) => {
  logger.debug("uploadReportContent", reportId, events);
  const preparedEvents = events.map(({ inspect, ...event }) =>
    JSON.stringify(
      {
        ...event,
        reportId,
      },
      inspect ? asSafeJsonReplacer : null
    )
  );

  const body = `[${preparedEvents.join(",")}]`;

  try {
    const response = await originalFetch(
      `${API_URL}/events/${type}${queryString}`,
      {
        method: "POST",
        headers: DEFAULT_HEADERS,
        body,
      }
    );

    if (!response.ok) {
      logger.error("Failed to fetch uploadReportContent PUT", response, {
        status: response.status,
      });
      onError?.(events);
      return null;
    }
  } catch (e) {
    logger.error("Failed to fetch uploadReportContent PUT", e);
    onError?.(events);
    return null;
  }
};
