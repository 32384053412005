const regexp = /([\w\-_]+)\.([\w\-_]+)\.([\w\-_]+)/gi;

const maskJWT = (str) => {
  const matches = str.match(regexp);

  if (!matches) {
    return str;
  }

  matches.forEach((matchValue) => {
    const match = matchValue.split(".");
    try {
      // JWT check
      const header = JSON.parse(atob(match[0]));
      const payload = JSON.parse(atob(match[1]));
      str = str.replace(matchValue, "********");
      // eslint-disable-next-line no-empty
    } catch (e) {}
  });

  return str;
};

export default maskJWT;
