import { getDateNow } from "./getDateNow";

const throttleFunction = (func, delayMs) => {
  let lastCall = 0;

  const _throttledFunc = (...args) => {
    let now = getDateNow();

    if (now - lastCall > delayMs) {
      lastCall = now;
      return func(...args);
    }
  };

  return _throttledFunc;
};

// export const debounceFunction = (func, delayMs) => {
//   let timeout;

//   const _debouncedFunc = (...args) => {
//     clearTimeout(timeout);
//     timeout = setTimeout(() => func(...args), delayMs);
//   };

//   return _debouncedFunc;
// };

export default throttleFunction;
