export const removeFonts = (rule) =>
  rule
    ?.replace(
      // Remove font rules from css
      // See https://github.com/rrweb-io/rrweb/blob/ad9bc3ed191cda270895ff8f63b42f62c14616e9/packages/rrweb/test/events/style-sheet-text-mutation.ts#L107
      /@font-face\s*{[^}]*}/g,
      ""
    )
    .replace(
      /url\(data:(font\/woff2?|application\/font-woff2?);base64.+\)/g,
      "url()"
    );
