import { extractErrorProperties } from "../../../api";
import { getDateNow } from "../getDateNow";

export function interceptErrors(addActivity, saveReport) {
  const logError = (...args) => {
    addActivity({
      type: "console",
      timestamp: getDateNow(),
      data: {
        method: "error",
        args: [...args],
      },
    });
  };

  // Global script error handler:
  const onErrorHandler = (e) => {
    // eslint-disable-next-line no-console
    logError("Error:", extractErrorProperties(e) /* e is an ErrorEvent */);
    saveReport?.();
  };

  // Global promise rejection handler:
  const onUnhandledRejectionHandler = (e) => {
    // eslint-disable-next-line no-console
    logError("UnhandledRejection:", extractErrorProperties(e.reason));
    saveReport?.();
  };

  window.addEventListener("error", onErrorHandler);
  window.addEventListener("unhandledrejection", onUnhandledRejectionHandler);

  return {
    stop() {
      window.removeEventListener("error", onErrorHandler);
      window.removeEventListener(
        "unhandledrejection",
        onUnhandledRejectionHandler
      );
    },
  };
}
