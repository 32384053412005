import logger from "../../../logger";

export function interceptResources(addActivity) {
  let observer;

  try {
    const addResource = (entity) => {
      if (!entity) {
        return;
      }

      // Skip resources for fetch from the script
      const shouldSkipResource = entity.name.includes(
        `scriptVersion=${process.env.REACT_APP_VERSION}`
      );

      if (shouldSkipResource) {
        return;
      }

      addActivity({
        type: "resource",
        timestamp: window.performance.timeOrigin + entity.startTime ?? 0,
        data: {
          url: entity.name,
          ...entity.toJSON(),
        },
      });
    };

    if (window?.PerformanceObserver) {
      observer = new PerformanceObserver((list, observer) => {
        list.getEntries().forEach(addResource);
      });
      observer.observe({ type: "resource" });
    }

    (window.performance?.getEntriesByType("resource") ?? []).forEach(
      addResource
    );
  } catch (e) {
    logger.error("Intercept resources error =>", e);
  }

  return {
    stop() {
      if (!observer) {
        return;
      }

      observer.disconnect();
    },
  };
}
