export const isFont = (url) => {
  if (!url) {
    return false;
  }

  if (
    typeof url === "string" &&
    typeof url.match === "function" &&
    url.match(/\.woff|\.woff2|\.eot|\.ttf|\.otf|\.svg/)
  ) {
    return true;
  }

  if (
    typeof url === "object" &&
    typeof url.toString === "function" &&
    url.toString().match(/\.woff|\.woff2|\.eot|\.ttf|\.otf|\.svg/)
  ) {
    return true;
  }

  return false;
};
