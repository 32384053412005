import { removeAdoptoKeys } from "./exclusionUtils";
import maskUUIDs from "./maskUUIDs";
import maskJWT from "./maskJWT";

export const filterEntries = ({
  setting,
  includeFields = [],
  source,
  shouldRecordUUIDs = false,
} = {}) => {
  if (setting === "off") {
    return {};
  }

  const selectAll = () => setting === "all" || !setting;
  const selectOnlyIncluded = (key) =>
    setting === "custom" && includeFields.includes(key);

  return Object.fromEntries(
    Object.entries({ ...source })
      .filter(
        ([key, value]) =>
          key &&
          value &&
          removeAdoptoKeys(key) &&
          (selectAll() || selectOnlyIncluded(key))
      )
      .map(([key, value]) => [
        key,
        maskJWT(shouldRecordUUIDs ? value : maskUUIDs(value)),
      ])
  );
};
