import logger from "../../../logger";
import { shouldExcludeNode } from "../exclusionUtils";
import {
  getDataAttrs,
  getNodeOpeningTag,
  removeDigitsFromText,
} from "./_settings";
import throttleFunction from "../throttleFunc";
import { getDateNow } from "../getDateNow";

const genUid = () => Math.random().toString(34).slice(8);
const uids = new WeakMap(); // WM does not hold strong references to keys, so we don't need to worry about memory leaks

export const getTargetJSON = ({
  target,
  tagName = "",
  piiRemovalMode = "auto",
}) => {
  if (!target) {
    logger.warn("interceptClicks() - cannot serialize to JSON, no target");
    return {};
  }

  return {
    tagName: tagName || target.tagName,
    name: target.name,
    type: target.type,
    selected: target.selected,
    checked: target.checked,
    value:
      piiRemovalMode === "manual"
        ? target.value
        : removeDigitsFromText(tagName, target.value),
    text:
      piiRemovalMode === "manual"
        ? target.innerText?.substring?.(0, 100)
        : removeDigitsFromText(tagName, target.innerText?.substring?.(0, 100)),
    html: getNodeOpeningTag(target),
    href: target.href,
    placeholder: target.placeholder,
    className: target.className,
    id: target.id,
    dataAttrs: getDataAttrs(target),
  };
};

const getData = ({ tagName, e, target, piiRemovalMode }) => {
  // if we already have the target, then get its uid and pass the uid in the event data,
  // otherwise generate a new uid and pass it in the event data, and save it so we can find it later
  let uid;

  if (uids.has(target)) {
    uid = uids.get(target);
  } else {
    uid = genUid();
    uids.set(target, uid);
  }

  return {
    screenshotUrl: "",
    screenshotType: "none",
    pointerType: e.pointerType,
    button: e.button,
    targetUid: uid,
    target: getTargetJSON({ target, piiRemovalMode, tagName }),
    pageX: e.pageX,
    pageY: e.pageY,
  };
};

const hasPasswordAttribute = (target) =>
  target
    .getAttributeNames()
    .some((attribute) => target.getAttribute(attribute)?.includes("password"));

const blurListeners = new Set();

const _interceptBlur = ({
  target,
  tagName,
  previousValue,
  addActivity,
  getData,
  piiRemovalMode,
}) => {
  // lowercase tag names:
  const VALID_ELEMENTS = ["input", "textarea"];
  const EXCLUDE_INPUT_TYPES = [
    "submit",
    "button",
    "reset",
    "radio",
    "checkbox",
  ];

  if (!VALID_ELEMENTS.includes(tagName.toLowerCase())) {
    logger.debug("interceptBlur() - skipping ignored element");
    return;
  }

  if (
    tagName.toLowerCase() === "input" &&
    EXCLUDE_INPUT_TYPES.includes(target.type)
  ) {
    logger.debug("interceptBlur() - skipping ignored input type");
    return;
  }

  if (target?.id === "bugpilot-root") {
    return;
  }

  const onBlur = (e) => {
    const blurTarget = e.target;
    logger.log("onBlur() - blur event", { blurTarget });

    const data = getData({ tagName, e, target: blurTarget, piiRemovalMode });

    if (data.target.value === previousValue) {
      logger.debug(
        "onChange() - value did not change, not adding blur activity"
      );
      return;
    }

    const activity = {
      type: "pointer-blur",
      timestamp: getDateNow(),
      data,
    };

    if (hasPasswordAttribute(blurTarget)) {
      activity.data.target.value = "********";
      activity.data.target.text = "********";
    }

    logger.log("onBlur() - adding pointer-blur activity", activity);
    addActivity(activity);
    blurListeners.delete(blurTarget);
  };

  if (blurListeners.has(target)) {
    logger.debug("interceptBlur() - blur listener already added");
    return;
  }

  logger.debug("interceptBlur() - adding blur event listener");
  target.addEventListener("blur", onBlur, { once: true });
  blurListeners.add(target);
};

export function interceptClicks(addActivity, { blockClass, piiRemovalMode }) {
  const pointerListener = async (e) => {
    logger.debug("interceptClick event", e.target);

    if (e.target === window) {
      logger.debug("interceptClick() - ignoring window focus");
      return;
    }

    if (
      shouldExcludeNode({
        node: e.target,
        blockClass,
      })
    ) {
      logger.debug("interceptClicks() - excluded node");
      return;
    }

    if (e.target === document.body || e.target === document.documentElement) {
      // do not intercept clicks on body
      logger.debug("interceptClicks() - clicked on body or html");
      return;
    }

    let target = e.target;

    if (target?.tagName?.toLowerCase() === "path") {
      logger.debug("interceptClicks() - clicked on path, using closest svg");
      // for svgs, the click target is always path,
      // so we need to find the parent svg element
      target = target.closest("svg");
    }

    if (target?.tagName?.toLowerCase() === "option") {
      logger.debug(
        "interceptClicks() - clicked on option, using closest select"
      );
      // for select elements, the click target is always option,
      // so we need to find the parent select element
      target = target.closest("select");
    }

    if (!target) {
      logger.debug("interceptClicks() - no target");
      return;
    }

    const tagName = target?.tagName;

    if (!tagName) {
      logger.debug("interceptClicks() - no tag name");
      return;
    }

    const data = getData({ tagName, e, target, piiRemovalMode });
    const activity = {
      type: "pointer",
      timestamp: getDateNow(),
      data,
    };

    if (hasPasswordAttribute(target)) {
      activity.data.target.value = "********";
      activity.data.target.text = "********";
    }

    logger.debug("interceptClicks() - adding pointer activity", activity);
    addActivity(activity);

    // for some elements we also attach a blur listener
    // so we can display when the user has finished editing:
    _interceptBlur({
      target,
      previousValue: target.value,
      tagName,
      addActivity,
      getData,
      piiRemovalMode,
    });
  };

  const throttledListener = throttleFunction(pointerListener, 500);

  document.addEventListener("click", throttledListener);
  window.addEventListener("focus", throttledListener, /* bubble: */ true); // from https://stackoverflow.com/a/60290325

  return {
    stop() {
      document.removeEventListener("click", throttledListener);
      window.removeEventListener("focus", throttledListener, true);
    },
  };
}
