import logger from "../../../logger";

let cryptoAlerted = false;
export const generateUuid = () => {
  try {
    return crypto.randomUUID();
  } catch (e) {
    const ret =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);

    if (cryptoAlerted) {
      return ret;
    }

    cryptoAlerted = true;
    logger.error(
      "Crypto library not available. Are you accessing this from an insecure url? The report will not be recorded but this might change in the future. Make sure your pages are served via HTTPS."
    );
    return ret;
  }
};
