import logger from "../../logger";

export const registerHashChangeEvent = ({ onRequestStart }) => {
  const ADOPTO_START_RECORDING_FRAGMENTS = [
    "--adopto-start-recording",
    "--bugpilot-start-recording",
    "--bugpilot-verify-script",
    "--bugpilot-screenshot",
  ];

  const isScreenshotRequest = (hash) => {
    return hash.includes("--bugpilot-screenshot");
  };

  const getMode = (urlString) => {
    try {
      const url = new URL(urlString);
      const hash = url.hash;
      return isScreenshotRequest(hash) ? "screenshot" : "recording";
    } catch (e) {
      logger.debug("Error parsing URL in getMode()", e);
      return "recording";
    }
  };

  const listener = ({ newURL, oldURL }) => {
    if (
      !ADOPTO_START_RECORDING_FRAGMENTS.some((fragment) =>
        newURL.includes(fragment)
      )
    ) {
      return;
    }

    const triggerType = // if verify script, then it's a verify script
      newURL.includes("--bugpilot-verify-script")
        ? "verify-script"
        : "magic-link";

    // Start the Adopto recording flow
    onRequestStart({
      mode: getMode(window.location.href),
      metadata: {
        triggerType,
      },
    });

    // Restore the previous Hash in case the SPA
    // is using location-based routing.
    // Make sure you do not remove the if condition above
    // to avoid an infinite loop.
    window.location.hash = new URL(oldURL).hash || "";
  };

  // This condition is checked on page load,
  // because the hashchange would not be fired
  if (
    ADOPTO_START_RECORDING_FRAGMENTS.some((fragment) =>
      window.location.hash.includes(fragment)
    )
  ) {
    const triggerType = // if verify script, then it's a verify script
      window.location.hash.includes("--bugpilot-verify-script")
        ? "verify-script"
        : "magic-link";

    // Start the Adopto recording flow
    onRequestStart({
      mode: getMode(window.location.href),
      metadata: {
        triggerType,
      },
    });

    // Remove the hash
    window.location.hash = "";
  }

  window.addEventListener("hashchange", listener);
  return {
    remove() {
      window.removeEventListener("hashchange", listener);
    },
  };
};
