// An event interface for the event system
// =======================================

export class EventInterface {
  private _listeners: { [key: string]: Function[] } = {};

  public on = (event: string, callback: Function) => {
    if (!this._listeners[event]) {
      this._listeners[event] = [];
    }

    this._listeners[event].push(callback);

    return () => {
      this._listeners[event] = this._listeners[event].filter((listener) => {
        return listener !== callback;
      });
    };
  };

  public emit = (event: string, ...args: any[]) => {
    if (!this._listeners[event]) {
      return;
    }

    this._listeners[event].forEach((callback) => {
      callback(...args);
    });
  };
}
