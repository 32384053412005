import logger from "../logger";
import nanoid from "./libs/uuid";
import { Report } from "./Report";
import { registerHashChangeEvent } from "./libs/registerHashChangeEvent";
import { registerWebSocketListener } from "./libs/registerWebsocketListener";
import { registerHelpdeskEvent } from "./libs/registerHelpdeskListener";
import {
  AutopilotError,
  BugpilotConfig,
  Listener,
  Metadata,
  ReportData,
  User,
} from "./types";
import { generateReportId } from "./libs/generateReportId";
import { getDateNow } from "./libs/getDateNow";
import { Debugger } from "./Debugger";
import { AutopilotErrorDetector } from "./AutopilotErrorDetector";
import { EventInterface } from "./EventInterface";

const originalFetch = window.fetch;

class Bugpilot {
  config: BugpilotConfig = {
    workspaceId: null,
    workspaceSettings: null,
    user: null,
  };

  isInitialized: boolean = false;
  listeners: Listener[] = [];
  report: Report | null = null;
  detector: AutopilotErrorDetector | null = null;
  debugger: Debugger;
  eventInterface: EventInterface = new EventInterface();

  constructor() {
    this.debugger = new Debugger(this);
  }

  // SessionStorage getter/setter for reportId
  private get reportId() {
    return sessionStorage.getItem("Bugpilot::reportId") || null;
  }

  private set reportId(value) {
    if (!value) {
      sessionStorage.removeItem("Bugpilot::reportId");
      return;
    }

    sessionStorage.setItem("Bugpilot::reportId", String(value));
    document.cookie = `com.bugpilot.report.id=${this.config.workspaceId}:${String(value)}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
  }

  public get anonymousId() {
    // get a random id and store it in local storage
    // so it's consistent across page reloads
    let anonymousId = localStorage.getItem("Bugpilot::anonymousId");

    if (!anonymousId) {
      anonymousId = nanoid();
      localStorage.setItem("Bugpilot::anonymousId", anonymousId);

      // save the anonymous id in cookies as well
      // so it's available in the backend
      document.cookie = `com.bugpilot.user.anonymousid=${anonymousId}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
    }

    return anonymousId;
  }

  // Public method for SDK usage
  requestSessionUpload(
    metadata: Partial<Metadata> = {},
    reportData: Partial<ReportData> = {}
  ) {
    if (!this.report) {
      logger.warn("No report is currently active, cannot submit");
      return;
    }

    this.report.updateReportData({
      ...reportData,
      metadata: {
        triggerType: "sdk",
        ...(reportData.metadata || {}),
        ...metadata,
      },
    });
    return this.report.requestUpload();
  }

  saveReport(
    metadata: Partial<Metadata> = {},
    reportData: Partial<ReportData> = {}
  ) {
    this.requestSessionUpload(metadata, reportData);
  }

  init(initConfig: BugpilotConfig) {
    this.config = {
      ...this.config,
      ...(initConfig || {}),
    };

    document.addEventListener("readystatechange", () => {
      if (document.readyState === "complete") {
        this._asyncInit();
      }
    });

    if (document.readyState === "complete") {
      this._asyncInit();
    }
  }

  private _identify(userAttributes: Partial<User>) {
    logger.info("Bugpilot._identify() saving user info", userAttributes);
    this.config.user = {
      ...(this.config?.user || {}),
      ...userAttributes,
    };

    this.eventInterface.emit("userIdentified", this.config.user);

    if (this.report) {
      this.report.updateReportData({
        user: this.config.user,
      });
    }
  }

  logout() {
    this.config.user = null;

    if (this.report) {
      this.report.updateReportData({
        user: this.config.user,
      });
    }
  }

  identify(...args) {
    // (this method has a similar signature to analytics.js)
    logger.info("Bugpilot.identify() called with args", args);

    if (args.length === 0) {
      logger.error("Bugpilot.identify() called with no arguments");
      return;
    }

    if (args.length === 1) {
      // e.g., Bugpilot.identify({ id: "123", email: "..." })
      const attrs = args[0];

      if (typeof attrs !== "object") {
        logger.error(
          "Bugpilot.identify() called with invalid argument. Expected an object, got:",
          attrs
        );
        return;
      }

      if (!attrs.id && !this.config?.user?.id) {
        logger.warn(
          "Bugpilot.identify() user is still not identified. You should pass an object with an `id` property, got:",
          attrs
        );
      }

      this._identify(attrs);
      return;
    }

    const [userId = null, userAttributes = {}] = args;
    this._identify({ id: userId, ...userAttributes });
  }

  public showRecordingUI() {
    this.eventInterface.emit("requestUserRecording");
  }

  public showBanner({ text, type }: { text: string | null; type: "error" }) {
    const bannerDiv = document.createElement("div");
    bannerDiv.style.position = "fixed";
    bannerDiv.style.top = "10px";
    bannerDiv.style.left = "calc(50% - 300px)";
    bannerDiv.style.zIndex = "999999999";
    bannerDiv.style.width = "600px";
    bannerDiv.style.maxWidth = "75vw";
    bannerDiv.style.height = "auto";
    bannerDiv.style.padding = "10px 15px";
    bannerDiv.style.backgroundColor = "#EC515A";
    bannerDiv.style.color = "white";
    bannerDiv.style.fontSize = "14px";
    bannerDiv.style.textAlign = "left";
    bannerDiv.style.boxShadow = "0 0 10px rgba(0,0,0,0.5)";
    bannerDiv.style.borderRadius = "5px";
    bannerDiv.style.userSelect = "none";
    bannerDiv.style.display = "flex";
    bannerDiv.style.flexDirection = "row";
    bannerDiv.style.alignItems = "center";
    bannerDiv.style.justifyContent = "space-between";
    bannerDiv.style.gap = "10px";
    bannerDiv.style.opacity = "0.90";
    bannerDiv.style.transition = "opacity 0.1s ease-in-out";

    bannerDiv.innerHTML = `
    <span>${text || ""}</span>
    
    <span style="cursor: pointer;"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg></span>`;

    const closeButton = bannerDiv.querySelector("span:last-child");
    closeButton.addEventListener(
      "click",
      () => {
        if (!bannerDiv) {
          return;
        }

        bannerDiv.style.opacity = "0";
        setTimeout(() => {
          bannerDiv.remove();
        }, 110);
      },
      false
    );

    document.body.appendChild(bannerDiv);
  }

  private _registerListeners() {
    const listenersParams = {
      onRequestStart: (reportData: Partial<ReportData>) => {
        const { mode } = reportData;
        if (mode === "recording") {
          if (!this.report) {
            logger.error(
              "Bugpilot.onRequestStart() called with mode=recording but no report is active"
            );
            return;
          }

          this.report?.updateReportData(reportData);
          this.showRecordingUI();
          return;
        }

        this.requestSessionUpload({}, reportData);
      },
      workspace: this.config?.workspaceSettings,
      workspaceId: this.config?.workspaceSettings?.id,
      getUser: () => this.config?.user,
      getUserId: () =>
        this.config.user?.id ||
        this.config.user?.user_id ||
        this.config.user?.userId ||
        this.config.user?.email,
      onUserIdChange: (userId, attrs = {}) => {
        this.identify(userId, attrs);
      },
      connectWebSocket: () => {},
    };

    // Connect the Websocket
    const [wsListener, connectWebSocket] = registerWebSocketListener(
      listenersParams
    ) as [Listener, () => void];

    this.listeners.push(wsListener);
    listenersParams.connectWebSocket = connectWebSocket;

    if (this.config.workspaceSettings?.apiWebsocketMode === "always-on") {
      setTimeout(() => {
        // delay a bit, so we do not create useless connections when the user is
        // quickly navigating between pages
        connectWebSocket();
      }, 5 * 1000);
    }

    // Register the events listeners:
    this.listeners.push(registerHashChangeEvent(listenersParams));

    setTimeout(() => {
      if (this.config.workspaceSettings?.helpdeskRecordingMode !== "auto") {
        return;
      }
      // Wait for chat widgets to initialize, then add the
      // event listeners for the chat widget
      this.listeners.push(registerHelpdeskEvent(listenersParams));
    }, 5 * 1000);
  }

  private injectWidgetAndListen() {
    logger.info("Listening for bug reporting UI events");

    // Widget v3
    // listen for 'io.bugpilot.events.send-report' messages with email, description

    const cb = (event: MessageEvent) => {
      const msg = event.data;

      if (msg?.type !== "io.bugpilot.events.send-report") {
        return;
      }

      logger.debug("Received message from widget", msg);
      const { email, description } = msg?.data || {};

      this.requestSessionUpload(
        {
          triggerType: "widget",
          userProvidedDescription: description,
        },
        {
          user: {
            id: email,
            email,
            ...(this.config.user || {}),
          },
        }
      );
    };

    if (!this.config.workspaceSettings?.enableUserWidgetV2) {
      logger.warn(
        "Widget is disabled in config and will not render. Visit your bugpilot.io dashboard to enable the bug reporting widget"
      );
      return;
    }

    // register the listener
    window.addEventListener("message", cb);

    // load the widget script from jsdelivr
    // IMPORTANT: update the integrity hash when updating the widget version
    // IMPORTANT: and test it in a staging environment.
    const src =
      "https://cdn.jsdelivr.net/npm/@bugpilot/widget@1.14.0/dist/bugpilot-widget.js";

    // $ cat ./dist/bugpilot-widget.js | openssl dgst -sha384 -binary | openssl base64 -A | pbcopy
    const sri =
      "sha384-43+qf8MAnbzX+J4rZibUG8Ek5LkjYpWUBSgthrLx1VFG1MyrUivjwyb8K4Ze109m";

    const script = document.createElement("script");
    script.src = src;
    script.integrity = sri;
    script.async = true;
    script.crossOrigin = "anonymous";
    script.onload = () => {
      logger.debug("Widget script loaded");
    };
    script.onerror = () => {
      logger.error(
        "Widget script failed to load. Did you forget to update your Content-Security-Policy?"
      );
    };
    document.body.appendChild(script);
  }

  private _initializeSession = () => {
    const reportId = this.reportId || generateReportId();

    const report = new Report({
      id: reportId,
      config: this.config,
      onUpload: (type) => {
        logger.log("Upload completed for type =", type);
        this.debugger.lastSaveTimestamp = getDateNow();
      },
      onStop: () => {
        // When the report is stopped (e.g., user idle, duration limit,...)
        // we create a new report after a short delay
        logger.log("Session stopped, creating a new one in 30s");

        this.reportId = null;

        setTimeout(() => {
          this._initializeSession();
        }, 30 * 1000);
      },
      onError: this.autopilotErrorHandler,
      anonymousId: this.anonymousId,
    });

    this.debugger.report = report;
    this.report = report;
    this.reportId = reportId;
    report.start();
  };

  private _onAutopilotRequestUpload = async (
    error: AutopilotError
  ): Promise<any> => {
    let errorInfo: any = null;
    try {
      // send the error to the events-error endpoint for instructions on what to do with it
      // this endpoint is currently in dry-run mode, so it does not actually do anything
      const response = await originalFetch(
        `https://events-error.bugpilot.io/error`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            error,
            reportId: this.report?.id,
            workspaceId: this.config.workspaceSettings?.id,
            userId: this.report?.anonymousId,
            timestamp: getDateNow(),
            url: window.location.href,
            kind: "browser",
          }),
        }
      );

      const resultJson  = await response.json();

      // If the error is muted or ignored, we do not report it
      if (resultJson.message !== 'OK' || resultJson.decision !== true) {
        logger.log("Ignoring event because of negative decision");
        return;
      }

    } catch (e) {
      logger.log("Ignoring event because of upstream error code", e);
      return;
    }

    this.requestSessionUpload(
      {
        triggerType:
          // keep the initial trigger type if it was not autopilot that started
          // this report
          this.report?.reportData?.metadata?.triggerType ?? "autopilot",
        errorInfo,
      },
      {
        autopilotErrors: [error],
        reportGroupId: errorInfo?.originalReportId || undefined,
      }
    );
  };

  private autopilotErrorHandler = (error: AutopilotError) => {
    if (error.type === "fetch-error") {
      return;
    }

    logger.log("---> Auto detection", error);
    this._onAutopilotRequestUpload(error).catch((e) => {
      logger.error("Autopilot request upload error", e);
    });
  };

  private _initializeAutopilotDetector() {
    this.detector = new AutopilotErrorDetector({
      onError: this.autopilotErrorHandler,
    });
    this.detector.start();
  }

  private _asyncInit() {
    if (this.isInitialized) {
      logger.warn(
        "init() called more than once, this call has no effect other than updating the configuration"
      );
      return;
    }

    logger.debug("_asyncInit(): session");
    this._initializeSession();

    logger.debug("_asyncInit(): listeners");
    this._registerListeners();

    logger.debug("_asyncInit(): widget and listeners");
    this.injectWidgetAndListen();

    logger.debug("_asyncInit(): autopilot detector");
    this._initializeAutopilotDetector();

    logger.debug("_asyncInit(): all done");

    this.isInitialized = true;
  }
}

export default Bugpilot;
