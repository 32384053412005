import { RRWEB_CHECKOUT_INTERVAL_MS } from "../../config";
import rrwebRecord from "../rrweb-record";
import { generateUuid } from "./generateUuid";

export function interceptScreenCapture(
  addRRWebEvent,
  { blockClass, flags, piiRemovalMode }
) {
  const stopRrweb = rrwebRecord({
    emit(event, isCheckout) {
      event.id = generateUuid();
      // https://github.com/rrweb-io/rrweb/blob/master/guide.md#checkout
      addRRWebEvent(event, isCheckout || false);
    },
    // For drawing mode support change to true
    recordCanvas: false,

    // Privacy:
    blockClass,
    maskTextClass: "adopto-mask",
    maskAllInputs: piiRemovalMode === "manual" ? false : true,

    // https://github.com/rrweb-io/rrweb/blob/master/docs/recipes/optimize-storage.md
    sampling: {
      // https://github.com/rrweb-io/rrweb/blob/master/docs/recipes/canvas.md
      canvas: 15,
      mousemove: true,
      mouseInteraction: true,
      scroll: 300,
      media: 800,
      input: "last", // When input multiple characters, only record the final input
    },

    // https://github.com/rrweb-io/rrweb/blob/588164aa12f1d94576f89ae0210b98f6e971c895/packages/rrweb-snapshot/src/types.ts#L97-L108
    slimDOMOptions: {
      comment: false,
      headFavicon: false,
      headWhitespace: false,
      headMetaDescKeywords: false,
      headMetaSocial: false,
      headMetaRobots: false,
      headMetaHttpEquiv: false,
      headMetaAuthorship: false,
      headMetaVerification: false,
    },

    // https://developer.mozilla.org/en-US/docs/Web/API/OffscreenCanvas/convertToBlob
    dataURLOptions: {
      type: "image/webp",
      quality: 0.5,
    },

    // ff: experiment excluding stylesheets
    inlineStylesheet: flags.doNotInlineStylesheets ? false : true, // defaults to true

    // defaults:
    inlineImages: false,
    collectFonts: false,

    // https://github.com/rrweb-io/rrweb/blob/master/guide.md#checkout
    checkoutEveryNms: RRWEB_CHECKOUT_INTERVAL_MS,
    // checkoutEveryNth: 100,
  });

  return {
    stop() {
      stopRrweb();
    },
  };
}
