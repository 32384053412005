import Bugpilot from "./classes/Bugpilot";
import logger from "./logger";

(function () {
  logger.log("Welcome to Bugpilot 🪲");

  // Check if we are allowed to run on localhost
  //

  const isLocalUrl =
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1" ||
    window.location.port !== ""; // non-default port

  // if (isLocalUrl) {
  //   logger.error("Bugpilot cannot run on local or development URLs.");
  //   return;
  // }

  const pilot = new Bugpilot();

  window.Adopto = pilot;
  window.Bugpilot = pilot;

  // Check if config is correctly set
  //

  const config = window.AdoptoConfig || window.BugpilotConfig || {};

  if (!config.workspaceId) {
    logger.error(
      "Bugpilot cannot initialize because workspaceId is missing from config"
    );
    return;
  }

  if (!config.workspaceSettings) {
    logger.error(
      "Bugpilot cannot initialize because workspaceSettings is missing from config"
    );
    return;
  }

  if (config.workspaceSettings?.error === true) {
    logger.error(
      "Cannot load Bugpilot because workspace cannot be found:",
      config.workspaceSettings.message || "no message"
    );
    return;
  }

  if (config.workspaceSettings?.id !== config.workspaceId) {
    logger.error("Bugpilot cannot initialize because of workspaceId mismatch");
    return;
  }

  // Initialize Bugpilot
  //

  logger.debug("Bugpilot is initializing");
  pilot.init(config);
})();
