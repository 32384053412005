import { Report } from "../Report";
import { ReportData } from "../types";
import { filterEntries } from "./filterEntries";
import { getCookies } from "./getCookies";

const makeReportPayload = async ({
  report,
}: {
  report: Report;
}): Promise<ReportData> => {
  const company = report.company;
  const companyId = report.company.id;
  const reportData = report.reportData;

  // call the intercom api to get the current user id
  const userId =
    reportData?.user?.id ||
    reportData?.user?.user_id ||
    reportData?.user?.userId;
  const userEmail = reportData?.user?.email;

  // Important: userId must match the userId set in Intercom
  const chatUserId = userId || userEmail;

  // == IMPORTANT ==
  // When you add properties here, you need to think if they need to
  // be added to BLACKLISTED_KEYS in Bugpilot.js.
  // Add properties that changes frequently and you do not need to update
  // in the report. This is to limit th number of times updateReport is called.

  const reportObj = {
    title: "Untitled report",
    ...reportData,
    id: report.id,
    v: "20230315",
    eventsVersion: "20230710",
    companyId,
    user: reportData?.user,
    mode: "session",
    // @ts-ignore-next-line
    scriptVersion: process.env.REACT_APP_VERSION,
    url: window.location.href,
    pageTitle: document.title || "Untitled Page",
    userAgent: navigator.userAgent,
    language: navigator.language,
    source: "script",
    sourceInfo: {},
    localStorage: filterEntries({
      setting: company?.recordLocalStorage,
      includeFields: company?.recordLocalStorageFields,
      source: localStorage,
      shouldRecordUUIDs: company?.shouldRecordUUIDs,
    }),
    sessionStorage: filterEntries({
      setting: company?.recordSessionStorage,
      includeFields: company?.recordSessionStorageFields,
      source: sessionStorage,
      shouldRecordUUIDs: company?.shouldRecordUUIDs,
    }),
    cookies: filterEntries({
      setting: company?.recordCookies,
      includeFields: company?.recordCookiesFields,
      source: getCookies(),
      shouldRecordUUIDs: company?.shouldRecordUUIDs,
    }),
    screen: {
      width: window.screen.width,
      height: window.screen.height,
    },
    window: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    startedAt: report.uploadStartedAt,
    chatUserId,
    timeOrigin: window.performance?.timeOrigin,
    timing: {}, // 2023-12-08 we removed timing
    firstPaint:
      window.performance
        ?.getEntriesByType("paint")
        ?.find(({ name }) => name === "first-paint") ?? null,
    firstContentfulPaint:
      window.performance
        ?.getEntriesByType("paint")
        ?.find(({ name }) => name === "first-contentful-paint") ?? null,

    // @ts-ignore
    connection: navigator?.connection
      ? {
          // @ts-ignore
          effectiveType: navigator.connection.effectiveType,
          // @ts-ignore
          downlink: navigator.connection.downlink,
          // @ts-ignore
          rtt: navigator.connection.rtt,
        }
      : null,
  };

  return reportObj;
};

export default makeReportPayload;
