import { asSerializableValue } from "../../../api";
import { isFont } from "./isFont";
import { shouldInterceptBody } from "./_settings";
import { getDateNow } from "../getDateNow";

const originalFetch = fetch;

const IGNORED_URL_PARTS = [
  // Analytics & Session Recordings
  "analytics.google.com",
  "bugpilot.io",
  "cdn-cgi", // CloudFlare scripts
  "google-analytics.com",
  "google-analytics.com",
  "june.so",
  "plausible.io",
  "posthog.com",
  "segment.com",
  "segment.io",

  // Payment providers
  "paypal.com",
  "stripe.com",
  "paddle.com",
  "arcot.com",
  "paypalobjects.com",
];

const isIgnored = (url) => {
  if (!url || typeof url.toString !== "function") {
    return true;
  }

  const urlString = url.toString();
  return IGNORED_URL_PARTS.some((part) => urlString.includes(part));
};

const sendToBugpilot = async ({
  url,
  args,
  responseClone,
  typeError,
  addActivity,
  onError,
}) => {
  const { body, ...opts } = args[0] || {};
  const method = opts.method?.toUpperCase() || "GET";

  if (opts.headers) {
    const headers = Object.keys(opts.headers).map((key) => [
      key,
      key.toLowerCase(),
    ]);
    const authorizationHeader = headers.find(
      ([key, lowerKey]) => lowerKey === "authorization"
    );
    const xApiKeyHeader = headers.find(
      ([key, lowerKey]) => lowerKey === "x-api-key"
    );

    if (authorizationHeader) {
      opts.headers[authorizationHeader[0]] = "********";
    }

    if (xApiKeyHeader) {
      opts.headers[xApiKeyHeader[0]] = "********";
    }
  }

  const activity = addActivity({
    timestamp: getDateNow(),
    type: "fetch",
    data: {
      initiator: "fetch",
      url,
      args: [
        {
          ...opts,
          method,
          body,
        },
        ...args.slice(1),
      ],
    },
  });

  if (typeError) {
    addActivity({
      sourceRecordingId: activity.id,
      timestamp: getDateNow(),
      type: "fetchResponse",
      data: {
        ok: false,
        error: asSerializableValue(typeError),
        url,
        status: -1,
        isRedirected: false,
        headers: {},
        body: {},
      },
    });

    onError({
      type: "fetch-error",
      error: typeError,
      url,
      method,
      status: -1,
      timestamp: getDateNow(),
      recordingId: activity.id,
    });

    return;
  }

  const responseHeaders = Object.fromEntries([
    ...responseClone.headers.entries(),
  ]);

  let responseBody;

  if (
    shouldInterceptBody({
      url,
      contentType: responseHeaders["content-type"],
    })
  ) {
    try {
      responseBody = await responseClone.text();
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  addActivity({
    sourceRecordingId: activity.id,
    timestamp: getDateNow(),
    type: "fetchResponse",
    data: {
      ok: responseClone.ok,
      url: responseClone.url,
      status: responseClone.status,
      isRedirected: responseClone.redirected,
      headers: responseHeaders,
      body: responseBody,
    },
  });

  if (responseClone.status < 400 && responseClone.ok) {
    return;
  }

  onError({
    type: "fetch-error",
    url,
    method,
    status: responseClone.status,
    timestamp: getDateNow(),
    recordingId: activity.id,
  });
};

export function interceptFetch(addActivity, { onError }) {
  window.fetch = async (url, ...args) => {
    if (isFont(url) || isIgnored(url)) {
      return originalFetch(url, ...args);
    }

    let urlString = url
      ? typeof url === "string"
        ? url
        : url.url || url.toString()
      : window.location.href;

    try {
      const response = await originalFetch(url, ...args);
      const responseClone = response.clone();

      if (response.status)
        setTimeout(() => {
          // note: the .clone() method cannot be called in the setTimeout
          // because you cannot clone the body if the body was already consumed
          sendToBugpilot({
            url: urlString,
            args,
            responseClone,
            addActivity,
            onError,
          });
        }, 100);

      return response;
    } catch (e) {
      setTimeout(() => {
        sendToBugpilot({
          url: urlString,
          args,
          responseClone: {},
          typeError: e,
          addActivity,
          onError,
        });
      }, 100);

      throw e;
    }
  };

  return {
    stop() {
      window.fetch = originalFetch;
    },
  };
}
